import React from 'react'
import "../styles/ShortcutsFooter.sass"

const ShortcutsFooter = () => (
  <div className="shortcuts-footer">
    <div>Prev page (Shift + Left)</div>
    <div>•</div>
    <div>Next page (Shift + Right)</div>
  </div>
)

export default ShortcutsFooter;