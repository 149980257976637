
import React, { useState } from 'react'
import { feedbackService } from '../../../../services';

export function useFeedback () {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);

  const updateFeedbackText = (text) => {
    setFeedbackText(text);
  }

  const submitFeedback = async (page) => {
    try {
      setIsSubmittingFeedback(true)
      await feedbackService.sendFeedback(page, feedbackText);
      alert('I got that! Thanks :)');
      closeModal();
      setFeedbackText('');
    } catch (err) {
      console.log(err);
      alert("Something went wrong here. Care to let me know about it?");
    }

    setIsSubmittingFeedback(false);
  }

  const openModal = () => {
    setIsFeedbackModalOpen(true)
  }

  const closeModal = () => {
    setIsFeedbackModalOpen(false);
  }

  return {
    models: {
      modal: {
        isOpen: isFeedbackModalOpen,
      },
      feedbackText,
      isSubmittingFeedback
    },
    operations: {
      submitFeedback,
      updateFeedbackText,
      openModal,
      closeModal
    }
  }
}
