import React from "react"
import "../styles/SideTableOfContents.sass"
import { textUtils } from '../../../../utils/textUtils'

const SideTableOfContents = ({ headings }) => {
  const isPartTitlePage = headings.length === 2;

  if (isPartTitlePage) {
    return <div/>;
  }

  return (
    <section className="side-table-of-contents">
      <ul>
        {headings.slice(1).map((heading, key) =>
          key === 0 ? (
            <li>
              <b>{headings[0].value}</b>
            </li>
          ) : (
            <li key={key} className={`wiki-toc-heading-${heading.depth}`}>
              <a href={`#${textUtils.toKebabCase(heading.value)}`}>{heading.value}</a>
            </li>
          )
        )}
      </ul>
    </section>
  )
}

export default SideTableOfContents
