import { graphql } from 'gatsby';
import React, { useEffect } from "react"
import Layout from "../components/layout/components/Layout"
import { useAuth } from "../services/AuthProvider";
import Loading from "../components/shared/loading/components/Loading";
import NotAuthenticated from '../components/shared/notAuthenticated/components/NotAuthenticated'
import { useChapters } from "../services/useChapters";
import { useSearchAndSideNav } from "../services/useSearchAndSideNav";
import ShortcutsFooter from "../components/shared/shortcuts-footer/components/ShortcutsFooter";
import SEO from "../components/layout/components/SEO";
import SubmitFeedbackModal from "../components/shared/feedback/components/SubmitFeedbackModal";
import SideTableOfContents from "../components/shared/sideTableOfContents/components/SideTableOfContents";
import WikiPageContentContainer from "../components/shared/wikiPageContentContainer/components/WikiPageContentContainer";

export default function WikiPage ({ data, location }) {
  const post = data.markdownRemark;
  const { isAuthenticated, isCheckingAuthState } = useAuth();
  const { operations } = useChapters();
  const { search } = useSearchAndSideNav()

  const id = operations.getChapterOrSectionIdFromPath(location.pathname);

  useEffect(() => {
    try {
      const id = operations
        .getChapterOrSectionIdFromPath(location.pathname);
      operations.closeAllAndOpenChapterOrSection(id);
    } catch (err) {
      console.log(err);
    }
  }, [location.pathname, search.active])

  return (
    <Layout>
      <SEO
        title={post.headings[0].value}
        description={post.plainExcerpt}
      />
      {isCheckingAuthState ? (
        <Loading/>
      ) : isAuthenticated || operations.isPublicChapter(id) ? (
        <WikiPageContentContainer>
          <div className="page-content">
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <ShortcutsFooter/>
            <SubmitFeedbackModal pageTitle={post.headings[0].value} path={location.pathname}/>
          </div>
          <SideTableOfContents headings={post.headings}/>
        </WikiPageContentContainer>
      ) : (
        <NotAuthenticated 
          headings={post.headings} 
          excerpt={post.excerpt}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 400, format: HTML)
      plainExcerpt: excerpt(pruneLength: 400, format: PLAIN)
      headings {
        depth
        value
      }
    }
  }
`