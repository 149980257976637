
import React from 'react'
import "../styles/TextArea.sass"

const TextArea = ({ placeholder, onChange }) => (
  <textarea 
    className="textarea"
    onChange={onChange}
    placeholder={placeholder}
  />
)

export default TextArea