
import React from 'react'
import "./SubmitFeedbackModal.sass"
import Button from '../../button/components/Button';
import TextArea from '../../input/components/TextArea'
import Modal from '../../modal/Modal';
import { useFeedback } from '../hocs/useFeedback';

function SubmitFeedbackModal (props) {
  const { models, operations } = useFeedback();
  const { pageTitle, path } = props;

  return (
    <>
      <div onClick={() => operations.openModal()} className="feedback-button">📣 &nbsp; Submit feedback</div>
      <Modal
        isOpen={models.modal.isOpen}
        loading={models.isSubmittingFeedback}
        onClose={() => operations.closeModal()}
      >
        <div className="submit-feedback-modal">
          <i style={{ textAlign: 'center'}}>Leaving feedback for "{pageTitle}"</i>
          <br/>
          <br/>
            
            <div>
              <TextArea 
                placeholder="Your feedback" 
                onChange={(e) => operations.updateFeedbackText(e.target.value)}
              />
              <Button
                text="Submit edit / feedback"
                onClick={() => operations.submitFeedback(path)}
              />
            </div>
        </div>
      </Modal>
    </>
  )
}

export default SubmitFeedbackModal;
